import { Td } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { LogQueryMethods, LogQueryState } from '../../../context/LogQuery/LogQueryInterface';
import { LogResultMethods } from '../../../context/LogResult/LogResultInterface';
import FilterTag from '../FilterTag';

export enum Level {
  FATAL = 'fatal',
  ERROR = 'error',
  WARN = 'warn',
  INFO = 'info',
  DEBUG = 'debug',
  TRACE = 'trace'
}

export interface ColumnLevelProps {
  value: string;
  columnName: string;
  logQueryState: LogQueryState;
  updateLogQueryObject: LogQueryMethods['updateLogQueryObject'];
  executeLogQuery: LogResultMethods['executeLogQuery'];
}

export default function ColumnLevel(props: ColumnLevelProps): ReactElement {
  const LevelColorScheme: { [key: string]: string } = {
    [Level.FATAL]: 'red',
    [Level.ERROR]: 'orange',
    [Level.WARN]: 'yellow',
    [Level.INFO]: 'linkedin',
    [Level.DEBUG]: 'blue',
    [Level.TRACE]: 'gray'
  };

  return (
    <Td width={100}>
      <FilterTag {...props} colorScheme={LevelColorScheme[props.value] || LevelColorScheme[Level.INFO]} />
    </Td>
  );
}
