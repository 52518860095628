import { Td } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export interface ColumnTimestampProps {
  timestamp: string;
}

export default function ColumnTimestamp({ timestamp }: ColumnTimestampProps): ReactElement | null {
  return <Td width={200}>{timestamp}</Td>;
}
