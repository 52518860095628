import React, { useContext } from 'react';
import { Box, Button, Heading, Link, useColorModeValue } from '@chakra-ui/react';
import { LogQueryContext, defaultLogQueryObject } from './context/LogQuery/LogQueryContext';
import { CookiesContext } from './context/Cookies/CookiesContext';
import { LogResultContext } from './context/LogResult/LogResultContext';
import LogQueryService from './services/LogQueryService';
import ColorModeSwitcher from './components/ColorModeSwitcher';
import LogGroups from './components/LogGroups';
import LogQuery from './components/LogQuery';
import ActionButton from './components/ActionButton';
import AlertBar from './components/AlertBar';
import LoaderBar from './components/LoaderBar';
import LogTable from './components/LogTable/LogTable';

export default function App() {
  const { partialResult, executeLogQuery, cancelLogQuery } = useContext(LogResultContext);
  const cookies = useContext(CookiesContext);
  const { dateRange, logGroups, logQueryObject, updateLogQueryObject, isValid } = useContext(LogQueryContext);

  const awsLink = LogQueryService.buildAwsLink({
    logQueryObject,
    region: cookies.region,
    logGroups,
    dateRange
  });

  return (
    <Box p={2}>
      <Heading mb={4}>
        CloudWatch Log Viewer
        <ColorModeSwitcher float={'right'} _hover={{ bg: useColorModeValue('gray.100', 'gray.600') }} />
      </Heading>
      <LogGroups />
      <LogQuery executeLogQuery={executeLogQuery} partialResult={partialResult} />

      <ActionButton
        visible={!partialResult}
        text={'Run query'}
        colorScheme={'blue'}
        disabled={!isValid}
        onClickCallback={() => executeLogQuery({ logGroups, dateRange, logQueryObject, isValid })}
      />
      <ActionButton visible={partialResult} text={'Cancel'} colorScheme={'orange'} onClickCallback={() => cancelLogQuery()} />
      <ActionButton
        visible={JSON.stringify(logQueryObject) !== JSON.stringify(defaultLogQueryObject) || !isValid}
        text={'Reset to default'}
        colorScheme={'gray'}
        onClickCallback={() => updateLogQueryObject(defaultLogQueryObject)}
      />
      <Button size={'sm'} mb={2} float={'right'}>
        <Link href={awsLink} isExternal>
          Open in AWS
        </Link>
      </Button>
      <AlertBar />
      <LoaderBar partialResult={partialResult} />
      <LogTable />
    </Box>
  );
}
