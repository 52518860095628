import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import * as React from 'react';
import './index.css';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import LogQueryProvider from './context/LogQuery/LogQueryContext';
import CookiesProvider from './context/Cookies/CookiesContext';
import LogQueryParams from './components/LogQueryParams';
import LogDataProvider from './context/LogResult/LogResultContext';
import AlertProvider from './context/Alert/AlertContext';

ReactDOM.render(
  <React.StrictMode>
    <ColorModeScript />
    <CookiesProvider>
      <AlertProvider>
        <LogQueryProvider>
          <ChakraProvider>
            <LogQueryParams />
            <LogDataProvider>
              <App />
            </LogDataProvider>
          </ChakraProvider>
        </LogQueryProvider>
      </AlertProvider>
    </CookiesProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
