import React, { PropsWithChildren, ReactElement, useContext, useMemo, useReducer, useState } from 'react';
import {
  Action,
  ActionExecuteQuery,
  ActionPartialResult,
  ActionQueryCancelled,
  ActionQueryCompleted,
  ActionType,
  LogResultMethods,
  LogResultState,
  QueryResultStatus
} from './LogResultInterface';
import { CookiesContext } from '../Cookies/CookiesContext';
import AWS, { CloudWatchLogs } from 'aws-sdk';
import moment from 'moment';
import LogQueryService from '../../services/LogQueryService';
import { GetQueryResultsRequest, StartQueryResponse } from 'aws-sdk/clients/cloudwatchlogs';
import { Link } from '@chakra-ui/react';
import { AlertContext } from '../Alert/AlertContext';
import { AlertStatus } from '../Alert/AlertInterface';
import { AWSError } from 'aws-sdk/lib/error';
import { LogQueryId, LogQueryState } from '../LogQuery/LogQueryInterface';

const logDataState: LogResultState = {
  logs: [],
  partialResult: false
};

function reducer(state: LogResultState, action: Action): LogResultState {
  switch (action.type) {
    case ActionType.EXECUTE_QUERY: {
      return { logs: [], partialResult: true };
    }

    case ActionType.PARTIAL_RESULT:
      return {
        logs: (action as ActionPartialResult).logs,
        partialResult: true
      };

    case ActionType.QUERY_COMPLETED:
      return {
        logs: (action as ActionQueryCompleted).logs,
        partialResult: false
      };

    case ActionType.QUERY_CANCELLED:
      return {
        ...state,
        partialResult: false
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const LogResultContext = React.createContext(logDataState as LogResultState & LogResultMethods);

export default function LogResultProvider({ children }: PropsWithChildren<{}>): ReactElement {
  const [state, dispatch] = useReducer(reducer, useContext(LogResultContext));
  const [queryId, setQueryId] = useState(undefined as LogQueryId | undefined);
  const { showAlert, hideAlert } = useContext(AlertContext);

  const cookies = useContext(CookiesContext);

  const cloudWatchLogs: AWS.CloudWatchLogs = useMemo(
    () =>
      new AWS.CloudWatchLogs({
        accessKeyId: cookies.id,
        secretAccessKey: cookies.key,
        sessionToken: cookies.token,
        region: cookies.region
      }),
    [cookies]
  );

  const runCloudWatchQuery = (logQueryState: LogQueryState) => {
    const {
      logGroups,
      logQueryObject,
      dateRange: { startDateRelative, customDate, startDate, endDate }
    } = logQueryState;
    hideAlert();

    const delayGetQueryResults = (queryId: GetQueryResultsRequest) => {
      setTimeout(() => {
        getQueryResults(queryId);
      }, 500);
    };

    const getQueryResults = (queryId: LogQueryId) => {
      cloudWatchLogs.getQueryResults(queryId, (err: AWSError, data: CloudWatchLogs.Types.GetQueryResultsResponse) => {
        if (!err) {
          switch (data.status as QueryResultStatus) {
            case QueryResultStatus.SCHEDULED:
              delayGetQueryResults(queryId);
              break;

            case QueryResultStatus.RUNNING:
              // Show sub-results and keep fetching more logs
              if (data.results && data.results.length) {
                dispatch({ type: ActionType.PARTIAL_RESULT, logs: data.results } as ActionPartialResult);
              }
              delayGetQueryResults(queryId);
              break;

            case QueryResultStatus.COMPLETE:
              console.log('Completed', data);
              if (data.results && data.statistics) {
                setQueryId(undefined);
                showAlert(AlertStatus.INFO, `Showing ${data.results.length} of ${data.statistics.recordsMatched} records matched`);
                dispatch({ type: ActionType.QUERY_COMPLETED, logs: data.results } as ActionQueryCompleted);
              }

              break;
            case QueryResultStatus.CANCELLED:
              setQueryId(undefined);
              showAlert(AlertStatus.WARNING, 'Query was cancelled.');
              dispatch({ type: ActionType.QUERY_CANCELLED } as ActionQueryCancelled);

              break;
            default:
              showAlert(AlertStatus.ERROR, `Error during fetching query results: ${data.status}`);
          }
        } else {
          console.log(err, err.stack);
          showAlert(AlertStatus.ERROR, `Error during fetching query results: ${err.message}`);
          dispatch({ type: ActionType.QUERY_COMPLETED, logs: [] } as ActionQueryCompleted);
        }
      });
    };

    if (!cookies.id && !cookies.key && !cookies.region && !cookies.token) {
      showAlert(
        AlertStatus.WARNING,
        <>
          Missing Credentials, login to{' '}
          <Link
            isExternal
            fontWeight={'bold'}
            color={'blue.500'}
            href={'https://eu-north-1.console.aws.amazon.com/cloudwatch/home?region=eu-north-1#dashboards:name=app-dev-dashboard'}
          >
            AWS CloudWatch Dashboard
          </Link>{' '}
          and open Log Viewer to obtain new credentials
        </>
      );
      return;
    }

    dispatch({ type: ActionType.EXECUTE_QUERY } as ActionExecuteQuery);

    const parsedRelativeDate = LogQueryService.parseRelativeDateRange(startDateRelative);
    const queryStartDate = customDate ? moment.utc(startDate) : moment().subtract(parsedRelativeDate.value, parsedRelativeDate.unit);
    // For pre-defined date range adding extra hour to end date as a prevention of client clock drift
    const queryEndDate = customDate ? moment.utc(endDate) : moment().add(1, 'hour');

    const params = {
      queryString: LogQueryService.buildLogQueryString(logQueryObject),
      startTime: queryStartDate.unix(),
      endTime: queryEndDate.unix(),
      logGroupNames: logGroups.filter((logGroup) => logGroup.checked).map((logGroup) => logGroup.name)
    };

    cloudWatchLogs.startQuery(params, (err, queryId: StartQueryResponse) => {
      if (!err && queryId.queryId) {
        setQueryId({ queryId: queryId.queryId });
        getQueryResults({ queryId: queryId.queryId });
      } else {
        console.log(err, err.stack);
        showAlert(AlertStatus.ERROR, `Error: ${err.name} - ${err.message}`);
        dispatch({ type: ActionType.QUERY_CANCELLED } as ActionQueryCancelled);
      }
    });
  };

  const actions: LogResultMethods = {
    executeLogQuery: (logQueryState: LogQueryState) => {
      if (queryId) {
        cloudWatchLogs.stopQuery(queryId, (err, response) => {});
      }
      
      // Only execute query when state is valid
      if (logQueryState.isValid) {
        runCloudWatchQuery(logQueryState);
      }
    },

    cancelLogQuery: () => {
      if (queryId) {
        cloudWatchLogs.stopQuery(queryId, (err, response) => {});
      }
    }
  };

  return <LogResultContext.Provider value={{ ...state, ...actions }}>{children}</LogResultContext.Provider>;
}
