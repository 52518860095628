import { QueryId } from 'aws-sdk/clients/cloudwatchlogs';

export interface LogGroup {
  name: string;
  checked: boolean;
}

export interface LogQueryId {
  queryId: QueryId;
}

export interface DateRange {
  startDateRelative: string;
  customDate: boolean;
  startDate: string;
  endDate: string;
}

export interface Filter {
  query: string;
  enabled: boolean;
}
export interface LogQueryObject {
  fields: string[];
  filter?: Filter[];
  sort?: string;
  limit?: number;
}

export interface LogQueryMethods {
  updateLogGroup: (logGroups: LogGroup[]) => void;
  updateStartDate: (startDate: string) => void;
  updateEndDate: (endDate: string) => void;
  updateStartDateRelative: (startDateRelative: string) => void;
  updateCustomDate: (customDate: boolean) => void;
  updateLogQueryObject: (logQueryObject: LogQueryObject) => void;
  updateIsValid: (isValid: boolean) => void;
}

export interface LogQueryState {
  logGroups: LogGroup[];
  dateRange: DateRange;
  logQueryObject: LogQueryObject;
  isValid: boolean;
}

export enum ActionType {
  UPDATE_LOG_GROUP = 'updateLogGroup',
  UPDATE_START_DATE = 'updateStartDate',
  UPDATE_END_DATE = 'updateEndDate',
  UPDATE_START_DATE_RELATIVE = 'updateStartDateRelative',
  UPDATE_CUSTOM_DATE = 'updateCustomDate',
  UPDATE_LOG_QUERY_OBJECT = 'updateLogQueryObject',
  UPDATE_IS_VALID = 'updateIsValid'
}

export interface Action {
  type: ActionType;
}

export interface ActionLogGroup extends Action {
  type: ActionType.UPDATE_LOG_GROUP;
  logGroups: LogGroup[];
}

export interface ActionStartDate extends Action {
  type: ActionType.UPDATE_START_DATE;
  startDate: string;
}

export interface ActionStartDate extends Action {
  type: ActionType.UPDATE_START_DATE;
  startDate: string;
}

export interface ActionEndDate extends Action {
  type: ActionType.UPDATE_END_DATE;
  endDate: string;
}

export interface ActionStartDateRelative extends Action {
  type: ActionType.UPDATE_START_DATE_RELATIVE;
  startDateRelative: string;
}

export interface ActionCustomDate extends Action {
  type: ActionType.UPDATE_CUSTOM_DATE;
  customDate: boolean;
}

export interface ActionLogQueryObject extends Action {
  type: ActionType.UPDATE_LOG_QUERY_OBJECT;
  logQueryObject: LogQueryObject;
}

export interface ActionIsValid extends Action {
  type: ActionType.UPDATE_IS_VALID;
  isValid: boolean;
}
