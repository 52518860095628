import moment from 'moment';
import { DateRange, Filter, LogGroup, LogQueryObject } from '../context/LogQuery/LogQueryInterface';

export enum QueryValueType {
  FIELDS = 'fields',
  FILTER = 'filter',
  SORT = 'sort',
  LIMIT = 'limit'
}

export enum DateRangeRelativeUnit {
  MINUTE = 'm',
  HOUR = 'h',
  DAY = 'd',
  WEEK = 'w'
}

export interface AwsLinkOptions {
  logQueryObject: LogQueryObject;
  region: string | undefined;
  logGroups: LogGroup[];
  dateRange: DateRange;
}

export interface RelativeDateRange {
  unit: DateRangeRelativeUnit;
  value: number;
}

export default class LogQueryService {
  /**
   * Parse query string into log query object for building CloudWatch queries
   * @param queryString
   */
  static parseLogQueryString(queryString: string | null): LogQueryObject | undefined {
    if (queryString) {
      const parsedLogQuery: LogQueryObject = { fields: [] };
      // Calculate range of the string that start with a line with #
      const disabledRanges = queryString
        .split('\n')
        .map((line, idx, list) => {
          return {
            disabled: line.trim().indexOf('#') === 0,
            startOffset: list.slice(0, idx).join('\n').length,
            endOffset: list.slice(0, idx).join('\n').length + line.length
          };
        })
        .filter((res) => res.disabled);
      // Split the string by | and calcluate if each segment are on a line that start with #
      const queryStringSegments = queryString.split('|').map((value, idx, list) => {
        const offset = list.slice(0, idx).join('|').length;
        return {
          value: value,
          enabled: disabledRanges.find(({ startOffset, endOffset }) => startOffset <= offset && endOffset >= offset) === undefined
        };
      });

      try {
        queryStringSegments.forEach((segment) => {
          let [, queryValueType, queryValue] = segment.value
            .trim()
            .split(new RegExp(`^(${QueryValueType.FIELDS}|${QueryValueType.FILTER}|${QueryValueType.SORT}|${QueryValueType.LIMIT})`));
          queryValueType = queryValueType.trim();
          // remove any space and # in end of string, since a common patten of input might be
          // {some value}{newline}#{space}, for example: `filter X\n# `
          queryValue = queryValue.trim().replace(/#$/, '').trim();

          switch (queryValueType) {
            case QueryValueType.FIELDS:
              queryValue.split(',').forEach((value) => parsedLogQuery.fields.push(value.trim()));
              break;
            case QueryValueType.FILTER: {
              if (parsedLogQuery.filter) {
                parsedLogQuery.filter.push({ query: queryValue, enabled: segment.enabled });
              } else {
                parsedLogQuery.filter = [{ query: queryValue, enabled: segment.enabled }];
              }
              break;
            }
            case QueryValueType.LIMIT: {
              parsedLogQuery[queryValueType] = parseInt(queryValue);
              break;
            }
            case QueryValueType.SORT: {
              parsedLogQuery[queryValueType] = queryValue;
              break;
            }
            default:
              throw new Error(`Unhandled query value type: ${queryValueType}`);
          }
        });
      } catch (e) {
        return undefined;
      }

      return parsedLogQuery;
    } else {
      return undefined;
    }
  }

  /**
   * Build a log query string for editing CloudWatch queries
   * @param queryObject
   * @returns {string}
   */
  static buildLogQueryString(queryObject: LogQueryObject): string {
    const queryBuilder = [`fields ${queryObject.fields.join(', ')}`];

    if (queryObject.filter && queryObject.filter.length) {
      const query = queryObject.filter.map((filter) => (filter.enabled ? '| filter ' : '# | filter ') + filter.query).join('\n');
      queryBuilder.push(query);
    }

    if (queryObject.sort) {
      queryBuilder.push(`| sort ${queryObject.sort}`);
    }

    if (queryObject.limit) {
      queryBuilder.push(`| limit ${queryObject.limit}`);
    }

    return queryBuilder.join('\n');
  }

  /**
   * Return format like {m: 5} for setting relative date (moment() - 5 minutes)
   * @param relativeDate
   */
  static parseRelativeDateRange(relativeDate: string): RelativeDateRange {
    const [relativeNumber, relativeUnit] = relativeDate.split(/([\D]+)/);
    return {
      unit: relativeUnit,
      value: parseInt(relativeNumber)
    } as RelativeDateRange;
  }

  /**
   * Build AWS Link to the CloudWatch Logs Insights with prefilled query data
   * @param logQueryObject
   * @param region
   * @param logGroups
   * @param dateRange
   */
  static buildAwsLink({ logQueryObject, region, logGroups, dateRange }: AwsLinkOptions): string {
    const { startDate, endDate, startDateRelative, customDate } = dateRange;
    // Calculate start time in seconds from relative date range like 30m, 1h, 1d
    const calculateStartInSeconds = (startDateRelative: string) => {
      const relativeDateRange: RelativeDateRange = LogQueryService.parseRelativeDateRange(startDateRelative);

      switch (relativeDateRange.unit) {
        case DateRangeRelativeUnit.MINUTE:
          return relativeDateRange.value * 60;
        case DateRangeRelativeUnit.HOUR:
          return relativeDateRange.value * 60 * 60;
        case DateRangeRelativeUnit.DAY:
          return relativeDateRange.value * 60 * 60 * 24;
        default:
          throw Error('Not supported relative date range unit');
      }
    };

    const timeType = customDate ? 'ABSOLUTE' : 'RELATIVE';
    let dateRangeString = customDate
      ? `~(end~'${moment(endDate).toISOString()}~start~'${moment(startDate).toISOString()}~timeType~'${timeType}~tz~'Local`
      : `~(end~0~start~-${calculateStartInSeconds(startDateRelative)}~timeType~'${timeType}~unit~'seconds`;

    // Prepare log group string, which contains encoded log group names separated with ~
    const logGroupsString = logGroups
      .filter((logGroup) => logGroup.checked)
      .map((logGroup) => encodeURI(logGroup.name))
      .join("~'");

    // Build log query string in format that is used in CloudWatch Logs Insights by escaping and replacing @ with %40
    const logQueryString = escape(LogQueryService.buildLogQueryString(logQueryObject)).replaceAll('@', '%40');

    // All % strings needs to be replaced with * to follow Log Insights format
    const queryDetailString =
      `${dateRangeString}~editorString~'${logQueryString}~isLiveTail~false~queryId~'~source~(~'${logGroupsString}))`.replaceAll('%', '*');

    // Escape query detail string and encoded it query param. All % strings needs to be replaced with $ to follow Log Insights format
    const queryStringAws = encodeURI(`?queryDetail=${escape(queryDetailString)}`).replaceAll('%', '$');

    return `https://${region}.console.aws.amazon.com/cloudwatch/home?region=${region}#logsV2:logs-insights${queryStringAws}`;
  }

  /**
   * Add or remove filter on the Log Query Object
   * @param logQueryObject
   * @param filterBy
   * @param filterField
   */
  static toggleLogQueryObjectFilter(logQueryObject: LogQueryObject, filterBy: string, filterField: string): LogQueryObject {
    const activeTraceIdFilter = logQueryObject.filter?.find((filter) => filter.query.includes(filterBy));
    const updatedFilters = activeTraceIdFilter
      ? LogQueryService.removeLogQueryObjectFilter(logQueryObject.filter, filterBy, filterField)
      : LogQueryService.mergeLogQueryObjectFilter(logQueryObject.filter, filterBy, filterField);

    return {
      ...logQueryObject,
      filter: updatedFilters
    };
  }

  /**
   * Merge log query filter by removing existing relevant filter condition and adding new ones
   * @param logQueryFilter
   * @param filterBy
   * @param filterField
   */
  static mergeLogQueryObjectFilter = (logQueryFilter: Filter[] | undefined, filterBy: string, filterField: string): Filter[] => {
    return LogQueryService.removeLogQueryObjectFilter(logQueryFilter, filterBy, filterField).concat([
      {
        query: `${filterField} like '${filterBy}'`,
        enabled: true
      }
    ]);
  };

  /**
   * Remove log query filter
   * @param logQueryFilter
   * @param filterBy
   * @param filterField
   */
  static removeLogQueryObjectFilter = (logQueryFilter: Filter[] | undefined, filterBy: string, filterField: string): Filter[] => {
    return (logQueryFilter || []).filter((value) => !value.query.match(new RegExp(`^${filterField} like '${filterBy}'$`)));
  };
}
