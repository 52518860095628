import {Td} from '@chakra-ui/react';
import React, {ReactElement} from 'react';

export interface ColumnServiceProps {
  value: string;
}

export default function ColumnDefault({value}: ColumnServiceProps): ReactElement {
  return <Td>{value}</Td>;
}
