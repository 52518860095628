import { Flex } from '@chakra-ui/react';
import { ReactElement, useContext } from 'react';
import { LogQueryContext } from '../../context/LogQuery/LogQueryContext';
import DatePicker from './DatePicker';
import RangeOptions from './RangeOptions';
import CustomDateSwitch from './CustomDateSwitch';

export default function DateRangePickers(): ReactElement {
  const {
    dateRange: { startDateRelative, customDate, startDate, endDate },
    updateStartDate,
    updateEndDate,
    updateStartDateRelative,
    updateCustomDate
  } = useContext(LogQueryContext);

  return (
    <Flex>
      {customDate ? (
        <>
          <DatePicker text={'From'} date={startDate} updateDate={updateStartDate} />
          <DatePicker text={'To'} date={endDate} updateDate={updateEndDate} />
        </>
      ) : (
        <RangeOptions relativeDateRange={startDateRelative} updateStartDateRelative={updateStartDateRelative} />
      )}
      <CustomDateSwitch customDate={customDate} updateCustomDate={updateCustomDate} />
    </Flex>
  );
}
