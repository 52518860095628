import { QueryResults } from 'aws-sdk/clients/cloudwatchlogs';
import { LogQueryState } from '../LogQuery/LogQueryInterface';

export enum ActionType {
  EXECUTE_QUERY = 'newQuery', // External
  PARTIAL_RESULT = 'partialResult', // Only internal
  QUERY_COMPLETED = 'queryCompleted', // Only internal
  QUERY_CANCELLED = 'queryCancelled' // Only internal
}

export interface Action {
  type: ActionType;
}

export interface QueryResultsInterface {
  logs: QueryResults;
}

export interface ActionExecuteQuery extends Action {
  type: ActionType.EXECUTE_QUERY;
}

export interface ActionPartialResult extends Action, QueryResultsInterface {
  type: ActionType.PARTIAL_RESULT;
}

export interface ActionQueryCompleted extends Action, QueryResultsInterface {
  type: ActionType.QUERY_COMPLETED;
}

export interface ActionQueryCancelled extends Action {
  type: ActionType.QUERY_CANCELLED;
}

export enum QueryResultStatus {
  SCHEDULED = 'Scheduled',
  RUNNING = 'Running',
  COMPLETE = 'Complete',
  CANCELLED = 'Cancelled'
}

export interface LogResultState {
  logs: QueryResults;
  partialResult: boolean;
}

export interface LogResultMethods {
  executeLogQuery: (logQueryState: LogQueryState) => void;
  cancelLogQuery: () => void;
}
