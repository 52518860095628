import { Td } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { LogQueryMethods, LogQueryState } from '../../../context/LogQuery/LogQueryInterface';
import { LogResultMethods } from '../../../context/LogResult/LogResultInterface';
import FilterTag from '../FilterTag';

export interface ColumnTraceIdProps {
  traceIds: string;
  columnName: string;
  logQueryState: LogQueryState;
  updateLogQueryObject: LogQueryMethods['updateLogQueryObject'];
  executeLogQuery: LogResultMethods['executeLogQuery'];
}

export default function ColumnTraceId({
  traceIds,
  columnName,
  logQueryState,
  updateLogQueryObject,
  executeLogQuery
}: ColumnTraceIdProps): ReactElement {
  return (
    <Td width={325} fontFamily={'monospace'}>
      {traceIds &&
        traceIds
          .split('/')
          .map((traceId) => (
            <FilterTag
              mr={2}
              key={traceId}
              logQueryState={logQueryState}
              executeLogQuery={executeLogQuery}
              columnName={columnName}
              updateLogQueryObject={updateLogQueryObject}
              value={traceId}
            />
          ))}
    </Td>
  );
}
