import { Code, Textarea, useColorModeValue, Box } from '@chakra-ui/react';
import LogQueryService from '../services/LogQueryService';
import { ReactElement, useCallback, useContext, useEffect, useState } from 'react';
import { LogQueryContext } from '../context/LogQuery/LogQueryContext';
import { LogResultMethods } from '../context/LogResult/LogResultInterface';
import { LogQueryObject } from '../context/LogQuery/LogQueryInterface';

export interface LogQueryProps {
  partialResult: boolean;
  executeLogQuery: LogResultMethods['executeLogQuery'];
}

export default function LogQuery({ partialResult, executeLogQuery }: LogQueryProps): ReactElement {
  const { logGroups, dateRange, logQueryObject, isValid, updateLogQueryObject, updateIsValid } = useContext(LogQueryContext);
  const [logQuery, setLogQuery] = useState(LogQueryService.buildLogQueryString(logQueryObject));

  const updateLogQuery = useCallback((): LogQueryObject | undefined => {
    const updatedLogQuery = LogQueryService.parseLogQueryString(logQuery);
    if (updatedLogQuery) {
      updateLogQueryObject(updatedLogQuery);
    } else {
      updateIsValid(false);
    }

    return updatedLogQuery;
  }, [logQuery, updateLogQueryObject, updateIsValid]);

  const keyDownHandler = useCallback(
    (event) => {
      if (event.keyCode === 13 && event.ctrlKey) {
        const updatedLogQuery = updateLogQuery();
        if (updatedLogQuery && !partialResult) {
          executeLogQuery({ dateRange, logGroups, logQueryObject: updatedLogQuery, isValid });
        }
      }
    },
    [updateLogQuery, partialResult, dateRange, logGroups, executeLogQuery, isValid]
  );

  useEffect(() => {
    document.addEventListener('keydown', keyDownHandler);
    return () => {
      document.removeEventListener('keydown', keyDownHandler);
    };
  }, [keyDownHandler]);

  useEffect(() => {
    if (isValid) {
      setLogQuery(LogQueryService.buildLogQueryString(logQueryObject));
    }
  }, [logQueryObject, isValid]);

  return (
    <Box mt={1}>
      <Code mb={2} p={4} display={'block'}>
        <Textarea
          isInvalid={!isValid}
          rows={logQuery.split('\n').length + 1}
          background={useColorModeValue('white', 'gray.200')}
          color='black'
          value={logQuery}
          onChange={(event) => setLogQuery(event.target.value)}
          onBlur={() => {
            updateLogQuery();
          }}
        />
      </Code>
    </Box>
  );
}
