import { ReactElement } from 'react';
import { Box, Input, Text } from '@chakra-ui/react';

export interface DateRangePickerProps {
  date: string;
  text: string;
  updateDate: (date: string) => void;
}

export default function DatePicker({ date, updateDate, text }: DateRangePickerProps): ReactElement {
  return (
    <Box ml={4} mb={1.5} display={'inline-flex'}>
      <Text fontWeight={'bold'} mt={1}>
        {text}:
      </Text>
      <Input type='datetime-local' value={date} step={1} size={'sm'} ml={2} onChange={(event) => updateDate(event.target.value)} />
    </Box>
  );
}
