import LogQueryService from '../services/LogQueryService';
import { useCallback, useContext, useEffect } from 'react';
import { LogQueryContext } from '../context/LogQuery/LogQueryContext';
import { LogGroup } from '../context/LogQuery/LogQueryInterface';

export default function LogQueryParams() {
  const {
    logGroups,
    dateRange: { startDateRelative, customDate, startDate, endDate },
    logQueryObject
  } = useContext(LogQueryContext);

  const updateHistory = (urlParams: URLSearchParams) => {
    window.history.pushState(null, '', window.location.pathname + '?' + urlParams.toString());
  };

  const updateLogGroupQueryString = useCallback((paramName: string, logGroups: LogGroup[]): void => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.delete(paramName);
    logGroups.forEach((logGroup) => urlParams.append(paramName, JSON.stringify(logGroup)));
    updateHistory(urlParams);
  }, []);

  const updateQueryStringParam = useCallback((paramName: string, paramValue: string): void => {
    const urlParams = new URLSearchParams(window.location.search);
    urlParams.set(paramName, paramValue);
    updateHistory(urlParams);
  }, []);

  useEffect(() => {
    updateLogGroupQueryString('log', logGroups);
  }, [logGroups, updateLogGroupQueryString]);

  useEffect(() => {
    updateQueryStringParam('startDateRelative', startDateRelative);
  }, [startDateRelative, updateQueryStringParam]);

  useEffect(() => {
    updateQueryStringParam('customDate', customDate.toString());
  }, [customDate, updateQueryStringParam]);

  useEffect(() => {
    updateQueryStringParam('startDate', startDate);
  }, [startDate, updateQueryStringParam]);

  useEffect(() => {
    updateQueryStringParam('endDate', endDate);
  }, [endDate, updateQueryStringParam]);

  useEffect(() => {
    updateQueryStringParam('query', LogQueryService.buildLogQueryString(logQueryObject));
  }, [logQueryObject, updateQueryStringParam]);

  return null;
}
