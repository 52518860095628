import { Button } from '@chakra-ui/react';
import { ButtonProps } from '@chakra-ui/button/dist/declarations/src/button';
import { ReactElement } from 'react';

export interface ActionButtonProps extends ButtonProps {
  onClickCallback: () => void;
  text: string;
  visible: boolean;
}

export default function ActionButton({
  colorScheme,
  size = 'sm',
  onClickCallback,
  text,
  visible,
  disabled
}: ActionButtonProps): ReactElement | null {
  return visible ? (
    <Button colorScheme={colorScheme} size={size} mb={2} mr={2} onClick={onClickCallback} disabled={disabled}>
      {text}
    </Button>
  ) : null;
}
