import { Tr } from '@chakra-ui/react';
import React, { ReactElement, useContext } from 'react';
import { LogQueryContext } from '../../context/LogQuery/LogQueryContext';
import { LogResultContext } from '../../context/LogResult/LogResultContext';
import ColumnTraceId from './Column/ColumnTraceId';
import ColumnLevel from './Column/ColumnLevel';
import ColumnTimestamp from './Column/ColumnTimestamp';
import ColumnService from './Column/ColumnService';
import ColumnDefault from './Column/ColumnDefault';
import ColumnExtra from './Column/ColumnExtra';
import ColumnMessage from './Column/ColumnMessage';

export default function LogTableContent(): ReactElement {
  const { logGroups, dateRange, logQueryObject, isValid, updateLogQueryObject } = useContext(LogQueryContext);
  const { logs, executeLogQuery } = useContext(LogResultContext);

  const renderField = (name: string, value: string, recordKey: string) => {
    const uniqueFieldKey = `${recordKey}-${name}`;
    switch (name) {
      case '@timestamp':
        return <ColumnTimestamp timestamp={value} key={uniqueFieldKey} />;
      case 'level':
        return (
          <ColumnLevel
            value={value}
            columnName={name}
            key={uniqueFieldKey}
            logQueryState={{ logGroups, dateRange, logQueryObject, isValid }}
            updateLogQueryObject={updateLogQueryObject}
            executeLogQuery={executeLogQuery}
          />
        );
      case 'service':
        return (
          <ColumnService
            value={value}
            columnName={name}
            key={uniqueFieldKey}
            logQueryState={{ logGroups, dateRange, logQueryObject, isValid }}
            updateLogQueryObject={updateLogQueryObject}
            executeLogQuery={executeLogQuery}
          />
        );
      case 'traceId':
        return (
          <ColumnTraceId
            traceIds={value}
            columnName={name}
            key={uniqueFieldKey}
            logQueryState={{ logGroups, dateRange, logQueryObject, isValid }}
            updateLogQueryObject={updateLogQueryObject}
            executeLogQuery={executeLogQuery}
          />
        );
      case 'extra':
        return <ColumnExtra json={value} key={uniqueFieldKey} />;
      case 'message':
        return <ColumnMessage message={value} recordKey={recordKey} key={uniqueFieldKey} />;
      default:
        return <ColumnDefault key={uniqueFieldKey} value={value} />;
    }
  };

  return (
    <>
      {logs.map((log, index) => {
        const logWithMappedFields: { [key: string]: string } = {};
        // Fill new object with key value pairs to match log query fields
        log.forEach((logObject) => {
          if (logObject.field && logObject.value) {
            logWithMappedFields[logObject.field] = logObject.value;
          }
        });

        return (
          <Tr key={logWithMappedFields['@ptr']}>
            {logQueryObject.fields.map((fieldName) => renderField(fieldName, logWithMappedFields[fieldName], logWithMappedFields['@ptr']))}
          </Tr>
        );
      })}
    </>
  );
}
