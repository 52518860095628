import { Progress } from '@chakra-ui/react';
import React, { ReactElement } from 'react';

export interface QueryStatusProp {
  partialResult: boolean;
}

export default function LoaderBar({ partialResult }: QueryStatusProp): ReactElement | null {
  return partialResult ? <Progress hasStripe={true} isIndeterminate mt={2} /> : null;
}
