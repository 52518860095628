import { Alert, AlertIcon, Text } from '@chakra-ui/react';
import React, { ReactElement, useContext } from 'react';
import { AlertContext } from '../context/Alert/AlertContext';

export default function AlertBar(): ReactElement | null {
  const { status, text } = useContext(AlertContext);

  return status && text ? (
    <Alert status={status} p={2}>
      <AlertIcon />
      <Text fontSize='sm'>{text}</Text>
    </Alert>
  ) : null;
}
