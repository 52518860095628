import { Accordion, AccordionButton, AccordionIcon, AccordionItem, AccordionPanel, Box, Td, Code, SkeletonText } from '@chakra-ui/react';
import React, { ReactElement, useContext, useState } from 'react';
import { CookiesContext } from '../../../context/Cookies/CookiesContext';
import AWS, { CloudWatchLogs } from 'aws-sdk';
import { LogRecord, LogRecordPointer } from 'aws-sdk/clients/cloudwatchlogs';
import { AWSError } from 'aws-sdk/lib/error';

export interface ColumnMessageProps {
  message: string;
  recordKey: LogRecordPointer;
}

export default function ColumnMessage({ message, recordKey }: ColumnMessageProps): ReactElement {
  const [logRecord, setLogRecord] = useState({} as LogRecord);
  const cookies = useContext(CookiesContext);
  const getLogRecord = () => {
    if (Object.keys(logRecord).length !== 0) {
      return;
    }

    const cloudWatchLogs: AWS.CloudWatchLogs = new AWS.CloudWatchLogs({
      accessKeyId: cookies.id,
      secretAccessKey: cookies.key,
      sessionToken: cookies.token,
      region: cookies.region
    });

    cloudWatchLogs.getLogRecord({ logRecordPointer: recordKey }, (err: AWSError, data: CloudWatchLogs.Types.GetLogRecordResponse) => {
      setLogRecord(data.logRecord || {});
    });
  };

  return (
    <Td>
      <Accordion allowToggle reduceMotion={true}>
        <AccordionItem borderWidth={0} _last={{ borderBottomWidth: 0 }}>
          <h2>
            <AccordionButton onClick={() => getLogRecord()}>
              <Box flex='1' textAlign='left'>
                {message}
              </Box>
              <AccordionIcon />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            {Object.keys(logRecord).length !== 0 ? (
              <table>
                <thead style={{ textAlign: 'left' }}>
                  <tr>
                    <th style={{ borderWidth: 0 }}>Field</th>
                    <th style={{ borderWidth: 0 }}>Value</th>
                  </tr>
                </thead>
                <tbody>
                  {Object.keys(logRecord).map((fieldName, index) => (
                    <tr key={index}>
                      <td style={{ background: 'none', borderWidth: 0 }}>
                        <Code background={'none'}>{fieldName}</Code>
                      </td>
                      <td style={{ background: 'none', borderWidth: 0 }}>
                        <Code background={'none'} wordBreak={'break-all'} style={{ background: 'none' }}>
                          {logRecord[fieldName]}
                        </Code>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            ) : (
              <SkeletonText mt='7' noOfLines={6} spacing='4' />
            )}
          </AccordionPanel>
        </AccordionItem>
      </Accordion>
    </Td>
  );
}
