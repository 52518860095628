import { Checkbox, Flex, Spacer, Stack, Text } from '@chakra-ui/react';
import { LogGroup } from '../context/LogQuery/LogQueryInterface';
import DateRangePickers from './DateRange/DateRangePickers';
import React, { useContext } from 'react';
import { LogQueryContext } from '../context/LogQuery/LogQueryContext';

export default function LogGroups() {
  const { logGroups, updateLogGroup } = useContext(LogQueryContext);

  return (
    <Flex>
      <Stack spacing={5} direction='row'>
        <Text fontWeight={'bold'} mt={1.5}>
          Log Groups:
        </Text>
        {logGroups.map((logGroup, index) => (
          <Checkbox
            key={logGroup.name}
            colorScheme='green'
            isChecked={logGroup.checked}
            onChange={(event) => {
              const updatedLogGroup: LogGroup[] = JSON.parse(JSON.stringify(logGroups));
              updatedLogGroup[index].checked = event.target.checked;

              updateLogGroup(updatedLogGroup);
            }}
          >
            {logGroup.name}
          </Checkbox>
        ))}
      </Stack>
      <Spacer />
      <DateRangePickers />
    </Flex>
  );
}
