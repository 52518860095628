import { UseRadioProps } from '@chakra-ui/radio/src/use-radio';
import { ReactElement, ReactNode } from 'react';
import { Box, HStack, useColorModeValue, useRadio, useRadioGroup } from '@chakra-ui/react';
import { LogQueryMethods } from '../../context/LogQuery/LogQueryInterface';

export interface DateRangeOptionsProps {
  relativeDateRange: string;
  updateStartDateRelative: LogQueryMethods['updateStartDateRelative'];
}

export interface RadioCardProps extends UseRadioProps {
  children: ReactNode;
}

export function RadioCard(props: RadioCardProps): ReactElement {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box as='label'>
      <input {...input} />
      <Box
        {...checkbox}
        cursor='pointer'
        borderWidth='1px'
        borderRadius='md'
        background={useColorModeValue('gray.300', 'gray.700')}
        boxShadow='sm'
        _checked={{ bg: 'blue.400', color: 'white', borderColor: 'teal.600' }}
        _focus={{ boxShadow: 'outline' }}
        px={4}
        py={1}
        mb={1}
      >
        {props.children}
      </Box>
    </Box>
  );
}

export default function RangeOptions({ relativeDateRange, updateStartDateRelative }: DateRangeOptionsProps): ReactElement {
  const options = ['5m', '30m', '1h', '3h', '12h'];
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: 'dateRange',
    defaultValue: relativeDateRange,
    onChange: (dateRange) => updateStartDateRelative(dateRange)
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {options.map((value) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio}>
            {value}
          </RadioCard>
        );
      })}
    </HStack>
  );
}
