import React, { PropsWithChildren, ReactElement, useState } from 'react';
import { useCookies } from 'react-cookie';
import { Cookies, CookiesParams } from './CookiesInterface';
import moment from 'moment';
import { Cookie, CookieSetOptions } from 'universal-cookie';


export const CookiesContext = React.createContext({} as Cookies);

/**
 * Initialize cookies from query string parameters and update current URL
 * @param cookies
 * @param setCookie
 * @returns {*}
 */
function initCookies(
  cookies: Cookies,
  setCookie: (name: CookiesParams, value: Cookie, options?: CookieSetOptions) => void
): Cookies {
  const urlParams = new URLSearchParams(window.location.search);
  const awsCookies: Cookies = {};

  Object.values(CookiesParams).forEach((queryKey) => {
    if (urlParams.has(queryKey)) {
      const expireDate = moment().add(12, 'hours').toDate();
      const cookieValue = decodeURI(urlParams.get(queryKey) || '');
      awsCookies[queryKey as CookiesParams] = cookieValue;
      setCookie(queryKey, cookieValue, { secure: true, expires: expireDate });
      urlParams.delete(queryKey);
    }
  });

  if (Object.keys(awsCookies).length !== 0) {
    // Remove query params from URL, which been set in cookie
    const newRelativePathQuery = window.location.pathname + '?' + urlParams.toString();
    window.history.pushState(null, '', newRelativePathQuery);
  }

  return { ...cookies, ...awsCookies };
}

export default function CookieProvider({ children }: PropsWithChildren<{}>): ReactElement {
  const [cookies, setCookie] = useCookies(Object.values(CookiesParams));
  const [state] = useState(initCookies(cookies, setCookie));

  return <CookiesContext.Provider value={{ ...state }}>{children}</CookiesContext.Provider>;
}
