import { Tag, TagLabel } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { LogQueryMethods, LogQueryState } from '../../context/LogQuery/LogQueryInterface';
import { LogResultMethods } from '../../context/LogResult/LogResultInterface';
import LogQueryService from '../../services/LogQueryService';
import { TagProps } from '@chakra-ui/tag/dist/declarations/src/tag';

export interface FilterTagProps extends TagProps {
  value: string;
  columnName: string;
  logQueryState: LogQueryState;
  updateLogQueryObject: LogQueryMethods['updateLogQueryObject'];
  executeLogQuery: LogResultMethods['executeLogQuery'];
}

export default function FilterTag({
  value,
  columnName,
  logQueryState,
  updateLogQueryObject,
  executeLogQuery,
  ...props
}: FilterTagProps): ReactElement {
  const { logQueryObject } = logQueryState;

  return (
    <Tag
      size={'md'}
      key={value}
      variant={logQueryObject.filter?.find((filter) => filter.query.includes(value)) ? 'solid' : 'outline'}
      colorScheme='linkedin'
      cursor={'pointer'}
      onClick={(event) => {
        const updatedLogQueryObject = LogQueryService.toggleLogQueryObjectFilter(logQueryObject, value, columnName);

        if (event.ctrlKey) {
          const urlParams = new URLSearchParams(window.location.search);
          urlParams.set('query', LogQueryService.buildLogQueryString(updatedLogQueryObject));
          window.open(`?${urlParams.toString()}`, '_blank');
        } else {
          updateLogQueryObject(updatedLogQueryObject);
          executeLogQuery({ ...logQueryState, logQueryObject: updatedLogQueryObject, isValid: true });
        }
      }}
      {...props}
    >
      <TagLabel>{value}</TagLabel>
    </Tag>
  );
}
