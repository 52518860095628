import { FormControl, FormLabel, Switch } from '@chakra-ui/react';
import { ReactElement } from 'react';
import {LogQueryMethods} from '../../context/LogQuery/LogQueryInterface';

export interface CustomDateSwitchProps {
  customDate: boolean;
  updateCustomDate: LogQueryMethods['updateCustomDate']
}

export default function CustomDateSwitch({ customDate, updateCustomDate }: CustomDateSwitchProps): ReactElement {
  return (
    <FormControl display='flex' alignItems='center' ml={2} mb={1}>
      <FormLabel htmlFor='custom-date' mb='0'>
        Custom
      </FormLabel>
      <Switch id='custom-date' size={'md'} isChecked={customDate} onChange={() => updateCustomDate(!customDate)} />
    </FormControl>
  );
}
