import React, { PropsWithChildren, ReactElement, useContext, useReducer } from 'react';
import {
  Action,
  ActionCustomDate,
  ActionEndDate,
  ActionIsValid,
  ActionLogGroup,
  ActionLogQueryObject,
  ActionStartDate,
  ActionStartDateRelative,
  ActionType,
  LogGroup,
  LogQueryMethods,
  LogQueryObject,
  LogQueryState
} from './LogQueryInterface';
import moment from 'moment';
import LogQueryService from '../../services/LogQueryService';

export const defaultLogQueryObject: LogQueryObject = {
  fields: ['@timestamp', 'level', 'service', 'traceId', 'message'],
  filter: [
    {
      query: 'ispresent(timestamp) AND ispresent(level) AND ispresent(service) AND ispresent(traceId) AND ispresent(message)',
      enabled: true
    }
  ],
  sort: '@timestamp desc',
  limit: 100
};

function initState(urlParamsString: string): LogQueryState {
  const urlParams = new URLSearchParams(urlParamsString);
  return {
    logGroups: urlParams.getAll('log').map((logGroup) => JSON.parse(logGroup)),
    dateRange: {
      startDateRelative: urlParams.get('startDateRelative') || '1h',
      customDate: urlParams.has('customDate') ? urlParams.get('customDate') === 'true' : false,
      startDate: (urlParams.has('startDate') ? moment(urlParams.get('startDate')) : moment().startOf('day')).format('yyyy-MM-DDTHH:mm:ss'),
      endDate: (urlParams.has('endDate') ? moment(urlParams.get('endDate')) : moment().endOf('day')).format('yyyy-MM-DDTHH:mm:ss')
    },
    logQueryObject: LogQueryService.parseLogQueryString(urlParams.get('query')) || defaultLogQueryObject,
    isValid: true
  };
}

function reducer(state: LogQueryState, action: Action): LogQueryState {
  switch (action.type) {
    case ActionType.UPDATE_LOG_GROUP:
      const logGroups = (action as ActionLogGroup).logGroups;
      return { ...state, logGroups: logGroups };

    case ActionType.UPDATE_START_DATE:
      const startDate = (action as ActionStartDate).startDate;
      return {
        ...state,
        dateRange: { ...state.dateRange, startDate: startDate }
      };

    case ActionType.UPDATE_END_DATE:
      const endDate = (action as ActionEndDate).endDate;
      return {
        ...state,
        dateRange: { ...state.dateRange, endDate: endDate }
      };

    case ActionType.UPDATE_START_DATE_RELATIVE:
      const startDateRelative = (action as ActionStartDateRelative).startDateRelative;
      return {
        ...state,
        dateRange: { ...state.dateRange, startDateRelative: startDateRelative }
      };

    case ActionType.UPDATE_CUSTOM_DATE:
      const customDate = (action as ActionCustomDate).customDate;
      return {
        ...state,
        dateRange: { ...state.dateRange, customDate: customDate }
      };

    case ActionType.UPDATE_LOG_QUERY_OBJECT:
      const logQueryObject = (action as ActionLogQueryObject).logQueryObject;
      return {
        ...state,
        logQueryObject: logQueryObject,
        isValid: true
      };

    case ActionType.UPDATE_IS_VALID:
      const isValid = (action as ActionIsValid).isValid;
      return {
        ...state,
        isValid: isValid
      };

    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
}

export const LogQueryContext = React.createContext(initState(window.location.search) as LogQueryState & LogQueryMethods);

export default function LogQueryProvider({ children }: PropsWithChildren<{}>): ReactElement {
  const [state, dispatch] = useReducer(reducer, useContext(LogQueryContext));

  const actions: LogQueryMethods = {
    updateLogGroup: (logGroups: LogGroup[]) => {
      dispatch({ type: ActionType.UPDATE_LOG_GROUP, logGroups } as ActionLogGroup);
    },

    updateStartDate: (startDate: string) => {
      dispatch({ type: ActionType.UPDATE_START_DATE, startDate } as ActionStartDate);
    },

    updateEndDate: (endDate: string) => {
      dispatch({ type: ActionType.UPDATE_END_DATE, endDate } as ActionEndDate);
    },

    updateStartDateRelative: (startDateRelative: string) => {
      dispatch({ type: ActionType.UPDATE_START_DATE_RELATIVE, startDateRelative } as ActionStartDateRelative);
    },

    updateCustomDate: (customDate: boolean) => {
      dispatch({ type: ActionType.UPDATE_CUSTOM_DATE, customDate } as ActionCustomDate);
    },

    updateLogQueryObject: (logQueryObject: LogQueryObject) => {
      dispatch({ type: ActionType.UPDATE_LOG_QUERY_OBJECT, logQueryObject } as ActionLogQueryObject);
    },

    updateIsValid: (isValid: boolean) => {
      dispatch({ type: ActionType.UPDATE_IS_VALID, isValid } as ActionIsValid);
    }
  };

  return <LogQueryContext.Provider value={{ ...state, ...actions }}>{children}</LogQueryContext.Provider>;
}
