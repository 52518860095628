import React, { PropsWithChildren, ReactElement, useContext, useState } from 'react';
import { AlertState, AlertMethods } from './AlertInterface';

export const AlertContext = React.createContext({} as AlertState & AlertMethods);

export default function AlertProvider({ children }: PropsWithChildren<{}>): ReactElement {
  const [state, setState] = useState(useContext(AlertContext) as AlertState);

  const actions: AlertMethods = {
    showAlert: (status: AlertState['status'], text: AlertState['text']) => setState({ status, text }),
    hideAlert: () => setState({})
  };

  return <AlertContext.Provider value={{ ...state, ...actions }}>{children}</AlertContext.Provider>;
}
