import { ReactElement } from 'react';

export enum AlertStatus {
  INFO = 'info',
  WARNING = 'warning',
  ERROR = 'error'
}

export interface AlertState {
  status ?: AlertStatus | undefined;
  text ?: ReactElement | string | undefined;
}

export interface AlertMethods {
  showAlert: (type: AlertState['status'], text: AlertState['text']) => void;
  hideAlert: () => void;
}
