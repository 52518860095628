import { ReactElement, useContext } from 'react';
import { Table, Tbody, Th, Thead, Tr } from '@chakra-ui/react';
import { LogQueryContext } from '../../context/LogQuery/LogQueryContext';
import { LogResultContext } from '../../context/LogResult/LogResultContext';
import React from 'react';
import LogTableContent from './LogTableContent';

export default function LogTable(): ReactElement | null {
  const { logs } = useContext(LogResultContext);
  const { logQueryObject } = useContext(LogQueryContext);

  return logs && logs.length ? (
    <Table colorScheme='gray' variant='striped' size='sm' mt={3}>
      <Thead>
        <Tr>
          {logQueryObject.fields
            .map((field) => (
              <Th key={field}>
                {field} {field === '@timestamp' ? '(UTC)' : ''}
              </Th>
            ))}
        </Tr>
      </Thead>
      <Tbody>
        <LogTableContent />
      </Tbody>
    </Table>
  ) : null;
}
