import {Td} from '@chakra-ui/react';
import React, {ReactElement} from 'react';

export interface ColumnExtraProps {
  json: string;
}

export default function ColumnExtra({json}: ColumnExtraProps): ReactElement {
  return <Td>
    <pre>{JSON.stringify(json, null, 2)}</pre>
  </Td>;
}
