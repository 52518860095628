import { Td } from '@chakra-ui/react';
import React, { ReactElement } from 'react';
import { LogQueryMethods, LogQueryState } from '../../../context/LogQuery/LogQueryInterface';
import { LogResultMethods } from '../../../context/LogResult/LogResultInterface';
import FilterTag from '../FilterTag';

export interface ColumnServiceProps {
  value: string;
  columnName: string;
  logQueryState: LogQueryState;
  updateLogQueryObject: LogQueryMethods['updateLogQueryObject'];
  executeLogQuery: LogResultMethods['executeLogQuery'];
}

export default function ColumnService(props: ColumnServiceProps): ReactElement {
  return (
    <Td width={150}>
      <FilterTag {...props} />
    </Td>
  );
}
